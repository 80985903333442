import http from "../../node_modules/fe-infrastractures/src/utils/http";

const isUseEoLinker = false;

export const getPaymentList = (data) => {
  return http.post("/api/history/actions/payment-page", data, {
    loading: false,
    useBody: true,
    useEoLinker: isUseEoLinker,
  });
};
export const getHistoryElectionList = (data) => {
  return http.get("/api/lists/history-elections?electionStatus=" + data);
};
export const getPositionPreferenceList = (data) => {
  return http.get("/api/lists/positions/all", data, { useEoLinker: isUseEoLinker });
};


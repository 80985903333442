<template>
  <div>
    <b-tabs v-model="tabindex">
      <b-tab
        v-for="(item, index) in items"
        :title="item.title"
        :key="index"
        @click="clickTab(item)"
      />
    </b-tabs>
  </div>
</template>
<script>
import { PW_Worker_Portal_Code } from "@/router/permissionCode";
export default {
  data() {
    return {
      tabindex: this.tabIndex,
      items: [
         {
        title: this.$g("workHistory"),
        index: 0,
        route: "workTrainingHistory",
      },
      {
        title: this.$g("trainingHistory"),
        index: 1,
        route: "trainingHistory",
      },
      ],
    };
  },
  methods: {
    clickTab(item) {
      this.$router.push({
        name: item.route,
        query: {
          id: this.$route.query.id,
        },
      });
    },
  },
  props: {
    tabIndex: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    let arr = [];
    this.items.forEach((element)=>{
      if (element.index == 0 && this.$has(PW_Worker_Portal_Code.TrainingOrHistory.Worker)){
        arr.push(element);
      }
      if (element.index == 1 && this.$has(PW_Worker_Portal_Code.TrainingOrHistory.View)){
        arr.push(element);
      }
    });
    this.items = arr;
  },
};
</script>

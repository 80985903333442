<template>
  <div>
    <b-table
      :fields="fields"
      :items="items"
      class="wapHide"
      v-if="!onlyTableWap"
    >
      <template v-for="field in fields" #[cellSlotName(field)]="row">
        <slot :name="field.key" v-bind="row" v-if="field.key != 'action'">
          {{ row.value }}
        </slot>
        <slot v-else name="actions" v-bind="row.item"></slot>
      </template>
    </b-table>
    <div class="wapModel layoutTableCard">
      <div v-for="item in items" :key="item.id" class="tableGroup">
        <div class="tableList" v-for="(item2, index) in fields" :key="index">
          <template v-if="item2.key != 'action'">
            <div class="control-field">{{ item2.label }}:&nbsp;</div>
            <div class="control-value">
              <template v-if="Array.isArray(item[item2.key])">
                <div v-for="(item, index) in item[item2.key]" :key="index">
                  {{ item }}
                </div>
              </template>
              <template v-else>
                {{ item[item2.key] }}
              </template>
            </div>
          </template>
          <template v-if="item2.key == 'action'">
            <div class="text-right" style="flex: 1">
              <slot name="actions" v-bind="item"></slot>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    fields: {
      type: Array,
      default: null,
    },
    items: {
      type: Array,
      default: null,
    },
    onlyTableWap: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    cellSlotName(fieldName) {
      console.log("cell(" + fieldName.key + ")");
      return "cell(" + fieldName.key + ")";
    },
  },
};
</script>
import http from "../../node_modules/fe-infrastractures/src/utils/http";

const isUseEoLinker = false;

export const getPositionPreferenceList = (data) => {
  return http.get("/api/lists/positions/all", data, { useEoLinker: isUseEoLinker });
};

export const getClassTypeList = (data) => {
  return http.get("/api/lists/class-types", data, { useEoLinker: isUseEoLinker });
};


export const getTrainingTypeList = (data) => {
  return http.get("/api/enum/training-type", data, { useEoLinker: isUseEoLinker });
};

export const getWorkElectionCount = (data) => {
  return http.get("/api/history/actions/work-election-count", data, { useEoLinker: isUseEoLinker });
};

export const getWorkHistoryList = (data) => {
  return http.post("/api/history/actions/work-page", data, {
    loading: false,
    useBody: true,
    useEoLinker: isUseEoLinker,
  });
};

export const getWorkTrainingList = (data) => {
  return http.post("/api/history/actions/training-page", data, {
    loading: false,
    useBody: true,
    useEoLinker: isUseEoLinker,
  });
};

